"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Semaphore = void 0;
class Semaphore {
    constructor(maxConcurrency) {
        this.lockFactory = () => {
            return {
                release: this.exit,
            };
        };
        /**
         * Enters the semaphore. If the number of granted locks is less than
         * the semaphore's defined maximum concurrency, then the lock will be
         * immediately granted. Else, this method will wait for a lock to become
         * available and only resolve the returned promise when the lock has been granted.
         *
         * Callers must call `release()` on the returned lock object as soon as
         * the semaphore-guarded code has finished executing.
         * @returns A promise resolving to a SemaphoreLock when it becomes available.
         */
        this.enter = async () => {
            return (this.tryEnter() ||
                new Promise((resolve) => {
                    this.waitResolvers.push(resolve);
                }));
        };
        /**
         * Enter the semaphore if a lock is immediately available.
         * Returns undefined if a lock cannot be immediately acquired.
         *
         * Callers must call `release()` on the returned lock object as soon as
         * the semaphore-guarded code has finished executing.
         * @returns SemaphoreLock if a lock was immediately available, else undefined.
         */
        this.tryEnter = () => {
            if (this.activeLocks < this.maxConcurrency) {
                this.activeLocks++;
                return this.lockFactory();
            }
            return undefined;
        };
        this.exit = () => {
            const resolver = this.waitResolvers.shift();
            if (resolver) {
                // Grant the newly-freed lock to the next task awaiting
                // entry to the semaphore
                resolver(this.lockFactory());
                return;
            }
            this.activeLocks--;
        };
        this.activeLocks = 0;
        this.maxConcurrency = maxConcurrency;
        this.waitResolvers = [];
    }
}
exports.Semaphore = Semaphore;
